import React from 'react';

import RouteEnum from '../../RouteEnum';
import { useRouteContext } from '../../RouteProvider';
import PageLayout from '../../views/Layout/Page';
import WizardNavbar from '../../views/Layout/WizardNavbar';
import MetaTags from '../../views/MetaTags';
import VerifyEmail from '../../views/User/VerifyEmail';
import { WizardNavigationProvider } from '../../views/WizardNavigation';

export default function SignVerifyEmailPage() {
  const routeContext = useRouteContext();
  const query = new URLSearchParams(routeContext.location.search);

  const handleClose = () => {
    routeContext.navigate(routeContext.generatePath(RouteEnum.HOMEPAGE));
  };

  return (
    <PageLayout customNavbar={<WizardNavbar onClose={handleClose} />} hideFooter pageview="email_verification">
      <MetaTags title="sign.verifyEmail.meta.title" />
      <WizardNavigationProvider
        onReturnClick={handleClose}
        supportModal={{
          noOfFaqs: 12,
          pageview: 'signup_support',
        }}
        translationPrefix="user.registration"
      >
        <VerifyEmail code={query.get('code')} hash={query.get('hash')} />
      </WizardNavigationProvider>
    </PageLayout>
  );
}
