import React from 'react';

import Button from '../Button';

import { NavbarLogo } from './Navbar';

import styles from './navbar.module.scss';

interface Props {
  onClose(): void;
}

export default function WizardNavbar(props: Props) {
  return (
    <nav className={styles.wizardContainer}>
      <div className={styles.navbar}>
        <NavbarLogo />

        <div className={styles.wrapper} id="navbar">
          <div className={styles.navbarRight}>
            <Button className={styles.closeBtn} onClick={props.onClose}>
              <i className="icon icon-close" />
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
}
