import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { afterSignIn } from '../store/session';

export function usePrivateCallback(callback: () => void | Promise<void>, deps: UnsafeAny[] = []) {
  const dispatch: DispatchFunction = useDispatch();
  return useCallback(() => {
    dispatch(afterSignIn(callback));
  }, [dispatch, callback, ...deps]);
}
