import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePrivateCallback } from '../../hooks/usePrivateCallback';
import { usePageView } from '../../hooks/useTracking';
import { requestEmailVerification } from '../../model/UserVerification';
import {
  closeEmailVerification,
  closeUserRegistration,
  completeEmailVerification,
} from '../../modules/UserRegistration/SumsubUserRegistration';
import RouteEnum from '../../RouteEnum';
import { useRouteContext } from '../../RouteProvider';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { flashMessageError, flashMessageSuccess } from '../../store/session';
import Button from '../Button';
import { StaticImage } from '../Image';
import Loader from '../Loader';
import T from '../Translate';
import WizardNavigation from '../WizardNavigation';

import styles from './UserRegistrationEmailVerification.module.scss';

interface Props {
  code: string;
  hash: string;
}

export default function VerifyEmail({ hash, code }: Props) {
  const dispatch: DispatchFunction = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const autoVerify = useRef(true);
  const { navigate, generatePath } = useRouteContext();

  const handleResend = usePrivateCallback(async () => {
    try {
      await requestEmailVerification();
      dispatch(flashMessageSuccess('global.verificationEmailResent'));
      Tracking.track('USER_REG_EMAIL_VERIFICATION_RESENT');
    } catch (error) {
      dispatch(flashMessageError(error.message));
    }
  });

  useEffect(() => {
    const verifyEmail = async () => {
      if (!autoVerify.current) return;

      autoVerify.current = false;
      try {
        await dispatch(completeEmailVerification(hash, code));
        Tracking.track('USER_REG_EMAIL_VERIFICATION_COMPLETED');
        setSuccess(true);
      } catch (err) {
        setError(err.message);
      }
    };

    if (!code || !hash) {
      navigate(generatePath(RouteEnum.NOT_FOUND));
      return;
    }
    verifyEmail();
  }, [code, hash]);

  if (error) {
    return <VerifyEmailError onResendClick={handleResend} />;
  }

  if (success) {
    return <VerifyEmailSuccess hash={hash} />;
  }

  return (
    <main className="main-container container">
      <Loader className="my-5 py-5" />
    </main>
  );
}

interface ErrorProps {
  onResendClick(): void;
}

function VerifyEmailError(props: ErrorProps) {
  const dispatch: DispatchFunction = useDispatch();
  const routeContext = useRouteContext();
  const state = useSelector((state: ReduxState) => ({
    email: state.userRegistration.email,
  }));

  usePageView('signup_emailverification_error');

  const handleClose = () => {
    dispatch(closeUserRegistration())(routeContext);
  };

  return (
    <main className="main-container container">
      <WizardNavigation />

      <div className="container container--md">
        <T as="h1" className={styles.title} id="email_verification_expire_title" />
        <T as="p" className={styles.description} id="email_verification_expire_description" multiline />

        {state.email ? <p className={styles.email}>{state.email}</p> : null}
        <div className={styles.resendContainer}>
          <T as="span" className={styles.textGrey} id="email_verification_v2_check_spam" />
          <Button className="button-inline" onClick={props.onResendClick} variant="link">
            <T id="user.registration.emailVerification.resend" />
          </Button>
        </div>

        <Button block className="text-muted mt-5" onClick={handleClose}>
          <T id="user_registration_email_save_and_finish_later" />
        </Button>
      </div>
    </main>
  );
}

function VerifyEmailSuccess({ hash }) {
  const dispatch: DispatchFunction = useDispatch();
  const routeContext = useRouteContext();
  usePageView('signup_emailverification_success');

  const handleContinue = () => {
    dispatch(closeEmailVerification(hash, routeContext));
  };

  return (
    <main className="main-container container">
      <WizardNavigation />

      <div className="container container--md">
        <T as="h1" className={styles.title} id="user.registration.emailVerificationDone.title" />

        <StaticImage className={styles.image} name="email-verification-done" />

        <Button block className="mt-5" icon onClick={handleContinue} primary>
          <T id="user.registration.emailVerification.continue" />
          <i className="icon icon-arrow-right-circle" />
        </Button>
      </div>
    </main>
  );
}
